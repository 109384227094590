import { GET_PRODUCTS_PACKAGES, GET_AVAILABLE_PACKAGES } from '@/config/endpoint';
import Req from '../AxiosRequest';

class GeneralInformation {
	constructor() {
		const axios = new Req();
		this.data = axios;
		this.errors = axios.errors;
		this.loading = axios.loading;
	}

	clear() {
		this.data.clear();
	}

	getPackages(options = {}) {
		const { method, endpoint } = GET_PRODUCTS_PACKAGES;
		const queryParams = Req.setFilters(options);
		return this.data[method](endpoint(queryParams)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getAvailablePackages(userId) {
		if (typeof userId === 'undefined') {
			return Promise.reject(new Error('user_id_required'));
		}
		const { method, endpoint } = GET_AVAILABLE_PACKAGES;
		return this.data[method](endpoint(userId)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default GeneralInformation;
