import {
	PICKUP_GET_PRODUCTS, PICKUP_GET_PRODUCT_DETAILS,
} from '@/config/endpoint';
import Req from '../AxiosRequest';

class AutoshipInformation {
	constructor() {
		const axios = new Req();
		this.data = axios;
		this.errors = axios.errors;
		this.loading = axios.loading;
		this.options = {};
	}

	clear() {
		this.data.clear();
	}

	getProducts(countryCode, type, options) {
		const category = type;
		const country = countryCode;
		let data = { country };
		if (typeof category !== 'undefined') {
			data.category = category;
		}

		if (typeof countryCode === 'undefined' || countryCode === 'membership') {
			console.warn('error in country');
			console.trace();
		}

		if (typeof options !== 'undefined') {
			data = { ...data, ...options };
		}

		const { method, endpoint } = PICKUP_GET_PRODUCTS;

		this.options.data = data;
		this.options.url = endpoint;
		this.options.method = method;

		return this.data.getBodyData(this.options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getProductDetails(productName) {
		const { method, endpoint } = PICKUP_GET_PRODUCT_DETAILS;
		return this.data[method](endpoint(productName)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default AutoshipInformation;
